
import axios from 'axios';

export async function getallBlogsPost() {
    try {
        const response = await axios.get('https://blog.trustid.one/public/blogapi');
     
        return response.data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        // throw error; // Re-throwing the error for the caller to handle
    }
}

